import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

// MUI imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

// Other imports
import Nav from 'react-bootstrap/Nav';
import { Image } from 'react-bootstrap';
import { AuthContext } from '../../config/auth';
import logoImage from '../../assets/img/brand/logo_mai_noback.png';

// Define routes directly in the component
const ROUTES = {
  Presentation: { path: "/" },
  Team: { path: "/team" },
  Signin: { path: "/signin" },
  Signup: { path: "/signup" },
  Bookdemo: { path: "/bookdemo" },
  AITAVR: { path: "/aitavr" },
  AIPE: { path: "/aipe" },
  Cttavr: { path: "/cttavr" },
};

// Navigation items
const pages = ['Team', 'Contact us'];
const hashLinks = {
  'Team': ROUTES.Team.path, 
  'Contact us': ROUTES.Bookdemo.path
};

// Styled Menu component
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

// Products Menu Component
const ProductMenu = () => {
  let history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="default"
        disableElevation
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        size="large"
        className="normal-case text-lg text-black mt-0.5"
      >
        Products
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
          history(ROUTES.AITAVR.path);
          setAnchorEl(null);
        }}>
          AI TAVR
        </MenuItem>
        <MenuItem onClick={() => {
          history(ROUTES.AIPE.path);
          setAnchorEl(null);
        }}>
          AI PE
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

// Main Component
function ResponsiveAppBar() {
  const { user } = React.useContext(AuthContext);
  let history = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  return (
    <AppBar position="static" color='transparent' elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className="justify-between">
          {/* Logo */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Nav.Link 
              eventKey={1} 
              as={HashLink} 
              to={ROUTES.Presentation.path} 
              className="text-lg relative"
            >
              <Image className="max-w-[300px]" src={logoImage} />
            </Nav.Link>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >

            <MenuItem key={0} onClick={() => setAnchorElNav(null)}>
              <Nav.Link eventKey={1} as={HashLink} to={ROUTES.Presentation.path} 
                style={{ fontSize:18 }}>
                <Typography textAlign="center">Home</Typography>
              </Nav.Link>
            </MenuItem>

              {pages.map((page, index) => (
                <MenuItem key={index} onClick={() => setAnchorElNav(null)}>
                  <Nav.Link eventKey={1} as={HashLink} to={hashLinks[page]} 
                    style={{ fontSize:18 }}>
                    <Typography textAlign="center" style={{ color:'black', }}>{page}</Typography>
                  </Nav.Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Action Buttons */}
          <div className="flex flex-row">
            <Box sx={{ flexGrow: 1 }}>
              <ProductMenu />
            </Box>

            <Button 
              onClick={() => history(ROUTES.Bookdemo.path)}
              variant="contained"
              size="large"
              className="normal-case text-lg"
            >
              Demo
            </Button>

            {/* {user ? (
              <Button 
                onClick={() => history(ROUTES.Cttavr.path)}
                variant="contained"
                size="large"
                className="normal-case text-lg"
              >
                App
              </Button>
            ) : (
              <Button 
                onClick={() => history(ROUTES.Signup.path)}
                variant="contained"
                size="large"
                className="normal-case text-lg"
              >
                Demo
              </Button>
            )} */}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;