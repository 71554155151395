import React from "react";
import { Grid, Container, Typography, Card, CardContent, CardMedia, useMediaQuery } from '@mui/material';
import { IconContext } from "react-icons";
import { BsLinkedin } from 'react-icons/bs';

import { FooterCustom } from "./FooterCustom";
import ResponsiveAppBar from "./ResponsiveAppBar";
import pavel from '../../assets/img/team/pavel.jpeg';
import susan from '../../assets/img/team/susan.jpeg';
import srini from '../../assets/img/team/srini.jpeg';
import sunny from '../../assets/img/team/sunny.jpeg';
import jake from '../../assets/img/team/jake.jpeg';
import taishi from '../../assets/img/team/taishi.jpeg';
import maarten from '../../assets/img/team/maarten.jpeg';
import faisal from '../../assets/img/team/faisal.jpeg';
import stephan from '../../assets/img/team/stephan.jpeg';
import augusto from '../../assets/img/team/augusto.jpeg';

export const Team = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const teamMembers = [
    {
      image: pavel,
      name: 'Pavel Overtchouk MD',
      text: 'Pavel is an AI engineer, physician, interventional cardiologist, researcher and full-stack developer. He is the founder and CEO of Medniscient.',
      linkedin: 'https://www.linkedin.com/in/pavelovertchouk/'
    },
    {
      image: susan,
      name: 'Susan Stretesky',
      text: 'Business development. Seasoned CV surgery revenue & go-to-market executive with 20+ years of experience. Former Medtronic sales leader.'
    },
    {
      image: sunny,
      name: 'Sunny Nadolsky',
      text: 'Seasoned healthcare business executive and advisor with 20+ years of experience. VC. Board member in multiple tech companies.'
    },
    {
      image: srini,
      name: 'Srinivas Achukola',
      text: 'Seasoned healthcare CTO, VP and operations advisor with 20+ years of experience. VC. Board member in multiple tech companies.'
    },
    {
      image: jake,
      name: 'Jake Williams',
      text: 'Jake is a value creator with 20 years of experience in finance, operations, and strategy as an investor and VC.'
    }
  ];

  const medicalAdvisors = [
    {
      image: taishi,
      name: 'Taishi Okuno MD',
      text: 'Interventional Cardiologist at Mitsui Memorial Hospital, Tokyo, Japan. Expert in complex coronary interventions and intracoronary imaging. Renowned researcher in cardiovascular medicine with numerous high-impact publications.',
    },
    {
      image: maarten,
      name: 'Maarten Vanhaverbeke MD',
      text: 'Interventional Cardiologist at Ghent University Hospital, Belgium. Specialist in complex PCI and structural heart interventions. Leading researcher in cardiovascular imaging and AI applications in cardiology.',
    },
    {
      image: faisal,
      name: 'Faisal Khan MD',
      text: 'Interventional Cardiologist at Saint George\'s Hospital, London, UK. Expert in complex coronary interventions and structural heart disease. Pioneer in implementing advanced cardiac imaging techniques in clinical practice.',
    },
    {
      image: stephan,
      name: 'Stephan Windecker MD',
      text: 'Director and Chief of Cardiology at Bern University Hospital, Switzerland. World-renowned expert in interventional cardiology. Principal investigator in numerous landmark clinical trials. Leader in cardiovascular medicine innovation.',
    },
    {
      image: augusto,
      name: 'Augusto Villa MD',
      text: 'Interventional Cardiologist at Jupiter Medical Center, Florida, USA. Specialist in complex coronary interventions and structural heart procedures.',
    }
  ];

  const CardContentLayout = ({ image, name, text, linkedin }) => (
    <div style={{ 
      display: 'flex', 
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center', 
      gap: '30px' 
    }}>
      <CardMedia
        component="img"
        className="rounded-circle img-fluid shadow shadow-lg--hover"
        style={{ 
          width: isMobile ? 200 : 150, 
          height: isMobile ? 200 : 150, 
          borderRadius: '10%',
          marginBottom: isMobile ? '15px' : 0
        }}
        image={image}
        alt={name}
      />
      <div>
        <Typography variant="h5" className="title" style={{ fontSize: 24, marginBottom: '10px' }}>
          {name}
        </Typography>
        <Typography variant="body1" className="description mt-3" style={{ fontSize: 20 }}>
          {text}
        </Typography>
        {linkedin && (
          <div style={{ marginTop: 15 }}>
            <a href={linkedin} target="_blank" rel="noreferrer">
              <IconContext.Provider value={{ size: 30 }}>
                <BsLinkedin />
              </IconContext.Provider>
            </a>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <ResponsiveAppBar />

      <br />
      <br />

      <section className="section">
        <Container>
          <Typography variant="h4" align="center" gutterBottom style={{ color: 'black' }}>
            World class healthcare technologists team
          </Typography>
          <br />
          <br />

          <Grid container direction="column" spacing={4}>
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={10} md={8} key={index}>
                <Card className="card-lift--hover shadow border-0 m-1" style={{ width: '100%' }}>
                  <CardContent>
                    <CardContentLayout {...member} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <br />
          <br />
          <Typography variant="h4" align="center" gutterBottom style={{ color: 'black' }}>
            Medical Advisors
          </Typography>
          <br />
          <br />
          <Grid container direction="column" spacing={4}>
            {medicalAdvisors.map((advisor, index) => (
              <Grid item xs={12} sm={10} md={8} key={index}>
                <Card className="card-lift--hover shadow border-0 m-1" style={{ width: '100%' }}>
                  <CardContent>
                    <CardContentLayout {...advisor} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>

      <FooterCustom />
    </>
  );
};