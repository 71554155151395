import { useNavigate } from "react-router-dom";

import { Container, Grid, Box } from '@mui/material';
import { Button } from '@mui/material';

import { RoutesNames } from "../routes";
import { FooterCustom } from "./dashboard/FooterCustom";
import ResponsiveAppBar from "./dashboard/ResponsiveAppBar";

import happy from '../assets/img/brand/happy_old_man1.jpg';
import happy1 from '../assets/img/brand/happy_old_woman1.jpg';

export const AITAVR = () => {
  let history = useNavigate();

  return (
    <>
      <ResponsiveAppBar/>

      <section className="py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={5}>
              <div className="flex flex-col items-center text-center space-y-6">
                <h2 className="text-3xl font-bold text-black">
                  AI TAVR for structural heart cardiologists and surgeons.
                </h2>

                <h4 className="text-xl text-black">
                  AI TAVR enhances clinicians with the ability to identify patients at high risk of stroke.
                </h4>

                <p className="text-black">
                  AI TAVR is an artificial intelligence that reads CT scans to predict the risk of surgical complications, like stroke.
                </p>

                <Button 
                  onClick={() => history(RoutesNames.Bookdemo.path)}
                  variant="contained"
                  className="rounded-full normal-case"
                >
                  Book a free demo
                </Button>
                <br/>
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <div className="flex justify-center">
                <img
                  src={happy}
                  alt="AI TAVR"
                  className="max-w-[500px] w-full rounded-tl-[40px] shadow-lg"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="py-12">
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} lg={5}>
              <div className="flex flex-col items-center text-center space-y-4">
                <h4 className="text-2xl font-semibold">Why using AI TAVR</h4>
                
                <p className="text-black">
                  <strong>Stroke</strong> occurs in approximately <strong>3% of transcatheter aortic valve replacement (TAVR) procedures</strong>.
                </p>
                <p className="text-black">
                  Even experienced operators cannot predict stroke.
                </p>
                <p className="text-black">
                  <strong>AI TAVR uses deep learning and computer vision to predict 9 out of 10 strokes</strong> related to TAVR.
                </p>
                <p className="text-black">
                  Predicting stroke during procedure planning could allow tailoring procedures to reduce the risk of stroke.
                </p>
                <p className="text-black">
                  AI TAVR also predicts other surgical risks.
                </p>
                
                <Button 
                  onClick={() => history(RoutesNames.Bookdemo.path)}
                  variant="contained"
                  className="rounded-full normal-case mt-4"
                >
                  Book a free demo
                </Button>
              </div>
            </Grid>
            
            <Grid item xs={12} lg={6} sx={{ marginTop: 5, }}>
              <div className="flex justify-center">
                <img
                  src={happy1}
                  alt="AI TAVR patient"
                  className="max-w-[500px] w-full rounded-bl-[40px] shadow-lg"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="py-12">
        <Container>
          <div className="text-center space-y-6">
            <h4 className="text-2xl font-semibold text-black">Our science</h4>
            
            <div className="space-y-2">
              <p className="font-bold">
                "Deep learning-based prediction of early cerebrovascular events after transcatheter aortic valve replacement."
              </p>
              <p>
                More than 1 million data points were used to train the AI TAVR deep learning predictive model.
              </p>
            </div>

            <Button 
              onClick={() => window.location.href = 'https://www.nature.com/articles/s41598-021-98265-5'}
              variant="contained"
              className="rounded-full normal-case"
            >
              Read the article
            </Button>
          </div>
        </Container>
      </section>

      <FooterCustom />

    </>
  );
};
